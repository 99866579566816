import { Component } from 'react';

export class Home extends Component {
  render() {
    return (
      <>
        <div className='home-featured'>
          <h2>Featured</h2>
          <p>Highlight important updates, tips, or new features.</p>
        </div>

        <div className='home-reminders border-top'>
          <h2>Reminders</h2>
          <p>Medication reminders and vet appointments.</p>
        </div>
        <div className='home-activities border-top'>
          <h2>Activity</h2>
          <p>Latest posts from pet social network, recent walks, and training progress.</p>
        </div>
        <div className='home-social border-top'>
          <h2>Pet Social</h2>
          <p>Pet social network, find friends and events.</p>
        </div>
        <a className='home-nearby border-top' href="./find-nearby">
          <h2>Find Nearby</h2>
          <p>Find vets, pet stores, parks, trails and accommodations</p>
        </a>



      </>
    )
  }
}
