//import { useEffect, useState } from 'react';

const HelpCenter = () => {
    return (<>
    <h1>Help Center</h1>
    </>);
};
export default HelpCenter;


