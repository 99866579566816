//import { useEffect, useState } from 'react';

const MyPets = () => {
  return (<>
    <div className='container mt-3'>
      <div className='row'>
        <div className='col-9'><h1>My Pets</h1></div>
        <div className='col-3 text-end'>
          <button type="button" className="btn btn-sm btn-add"><i className="fa-solid fa-plus"></i></button>
        </div>

      </div>
    </div>

  </>);
};
export default MyPets;

