import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useState } from 'react';
import { DogMatchOptions } from '../components/MatchMaker/DogOptions';
import { GenderTypes } from '../helpers/global';


//export default function DogProfile(props) {
  export default function DogProfile() {
  //const [editId, setEditId] = useState(0);
  const [name, setName] = useState('');
  const [gender, setGender] = useState(0);
  const [breed, setBreed] = useState('');
  const [doB, setDoB] = useState(new Date());


  return (
    <>
      <div className="container">
        <div className='mb-3'>
          <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={name} onChange={e => setName(e.value)} />
          <div id='tbNameError' className='error-message' />
        </div>

        <div className="row mb-2">
          <div className='mb-3 col-md-6'>
            <TextBoxComponent id='tbBreed' name='tbBreed' placeholder='Breed' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={breed} onChange={e => setBreed(e.value)} />
            <div id='tbBreedError' className='error-message' />
          </div>

          <div className='mb-3 col-6 col-md-3'>
            {/* <NumericTextBoxComponent  id='tbGender' name='tbGender' placeholder='Gender' floatLabelType='Auto' showClearButton={true} value={gender} onChange={e => setGender(e.value)} />  */}
            <DropDownListComponent placeholder='Gender' floatLabelType='Auto' showClearButton={true} dataSource={GenderTypes} fields={{ text: 'text', value: 'value' }} allowFiltering={true} value={gender} onChange={e => setGender(e.target.value)} width={"100%"} />
            <div id='tbGenderError' className='error-message' />
          </div>

          <div className='mb-3 col-6 col-md-3'>
            <DatePickerComponent id='tbDoB' name='tbDoB' placeholder='Birthday' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={doB} onChange={e => setDoB(e.value)} /> <div id='tbDoBError' className='error-message' />
          </div>
        </div>

        <DogMatchOptions />
      </div>

    </>
  )

}
