import { Component } from 'react';
import { getAuthToken } from '../../helpers/authentication';

export class PreLogin extends Component {

  constructor(props) {
    super(props);
    this.state = { hasLogin: false, currentOS: "", loading: true }
  }

  componentDidMount() {

    let currentOS = navigator.userAgent.match(/(Android)|(IPhone)|(Windows)/gi)[0];
    let hasLogin = getAuthToken() && getAuthToken() !== "";
    setTimeout(() => {
      this.setState({ currentOS, hasLogin, loading: false });
    }, 500)
  }



  render() {
    //let content = this.state.loading ? <Spinner color='primary' className='fixed-bottom  mx-auto mb-3 ' /> : this.renderLogin(this.state.hasLogin);
    return (
      <div className="h-100">

        <div className='landing-container h-100'>
          You need to login!
          {/* {content} */}
        </div>
      </div>
    )
  }
}
