import React, { Component } from 'react';
import { Route, Routes } from "react-router-dom";
import { DefaultLayout } from "./components/Layouts/DefaultLayout";
import { PreLogin } from "./components/Layouts/PreLogin";
import { getIsLoggedIn } from "./helpers/authentication";

import { Home } from "./pages/home";
import DogProfile from "./pages/dog-profile";
import FindNearby from "./pages/find-nearby-leaflet";
import HelpCenter from './pages/help-center';
import Medical from './pages/medical';
import MyPetProfile from './pages/my-pet-profile';
import MyProfile from './pages/my-profile';
import PetSocial from './pages/pet-social';
import Reminders from './pages/reminders';
import Services from './pages/services';
import EmergencyContacts from './pages/emergency-contacts';
import Training from './pages/training';
import Featured from './pages/featured';
import Activity from './pages/activity';
import MyPets from './pages/my-pets';


export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      defferedPrompt: null
    };

    this.contentRef = React.createRef();
  }

  componentDidMount() { }

  handleTouch() { }

  render() {
    return (
      <>
        {getIsLoggedIn() ? (
          <>
            {/* <FirebaseNotifications /> */}
            <div ref={this.contentRef} onTouchStart={this.handleTouch}>
              <Routes>
                <Route exact path="/" element={<DefaultLayout />}>
                  <Route index element={<Home />} />
                  <Route path="/find-nearby" element={<FindNearby />} />
                  <Route path="/dog-profile" element={<DogProfile />} />
                  <Route path="/help-center" element={<HelpCenter />} />
                  <Route path="/medical" element={<Medical />} />
                  <Route path="/my-pet-profile" element={<MyPetProfile />} />
                  <Route path="/my-profile" element={<MyProfile />} />
                  <Route path="/pet-social" element={<PetSocial />} />
                  <Route path="/reminders" element={<Reminders />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/emergency-contacts" element={<EmergencyContacts />} />
                  <Route path="/training" element={<Training />} />
                  <Route path="/featured" element={<Featured />} />
                  <Route path="/activity" element={<Activity />} />
                  <Route path="/my-pets" element={<MyPets />} />
                </Route>
              </Routes>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<PreLogin />} />
            {/* <Route path="/plain" element={<NoLayout />}>
              <Route
                path="/plain/updatepassword"
                element={<UpdatePassword />}
              />
              <Route path="/plain/selectgroup" element={<SelectGroup />} />
              <Route path="/plain/userotp" element={<OptLogin />} /> */}
            {/* </Route> */}
          </Routes>
        )}

      </>
    );
  }
}

