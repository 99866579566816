import { Component } from "react";
import { Link } from 'react-router-dom';


export class Footer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }
  
  render() {
    
    return (
        <>

        <footer>
          <table className="w-100 text-center">
            <tbody>
              <tr>
                <td><Link to="/"><i className="fas fa-home"></i></Link></td>
                <td><Link to="/pet-social"><i className="fas fa-paw"></i></Link></td>
                <td><Link to="/training"><i className="fas fa-dog-leashed"></i></Link></td>
                <td><Link to="/find-nearby"><i className="far fa-magnifying-glass-location"></i></Link></td>
                <td><i className="fas fa-ellipsis-h" data-bs-toggle="offcanvas" data-bs-target="#SideMenu" aria-controls="SideMenu"></i></td>
              </tr>
              <tr>
                <td><Link to="/">Home</Link></td>
                <td><Link to="/pet-social">Social</Link></td>
                <td><Link to="/training">Training</Link></td>
                <td><Link to="/find-nearby">Find</Link></td>
                <td><a role="button" data-bs-toggle="offcanvas" data-bs-target="#SideMenu" aria-controls="SideMenu" href="#SideMenu">More</a></td>
              </tr>
            </tbody>
          </table>
          
        </footer>


        <div className="offcanvas offcanvas-end" tabIndex="-1" id="SideMenu">
            <div className="offcanvas-body side-menu">
              <table className="w-100 my-4">
                <tbody>
                  <tr>
                    <td>
                      <h1>MENU</h1>
                    </td>
                    <td className="text-end">
                      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              


              <ul>
                <li><a className="navMen" href="/my-pets">My Pets</a></li>
                <li><a className="navMen" href="/my-profile">My Profile</a></li>
                <li><a className="navMen" href="/emergency-contacts">Emergency Contacts</a></li>
                <li><a className="navMen" href="/medical">Medical</a></li>
                <li><a className="navMen" href="/imfo">Info</a></li>
                <li><a className="navMen" href="/services">Services</a></li>
                <li><a className="navMen" href="/help-center">Help Center</a></li>
                <li><a className="navMen" href="/app-feedback">App Feedback</a></li>
                <li><a className="navMen" href="/">Install App</a></li>
              </ul>

            </div>
          </div>

      </>
    );
  }

  
}
