import { useState, useEffect } from 'react';

export const DogMatchOptions = () => {
  
  const btnActive = "me-2 btn btn-sm btn-selected mb-2";
  const btnInactive = "me-2 btn btn-sm btn-outline-dark mb-2";

  const [dogData, setDogData] = useState({
    energyLevels: 0,
    dogSize: 0,
    dogBuild: 0,
    dogTypeBreed: 0,
    temperament: 0,
    walkTime: [],
    walkDuration: 0,
    walkLocation: 0,
    healthConsiderations: 0,
    age: 0,
    walkingPace: 0,
    trainingLevel: 0,
    ownersAvailability: 0,
    playStyle: [],
    noiseSensitivity: 0,
    locationPreferences: [],
    weatherPreferences: 0,
    socialisationNeeds: 0,
  });
  
  const handleChange = (name, value) => {
    setDogData((prevDog) => ({ ...prevDog, [name]: value }));
  };

  const handleMultiSelectChange = (name, value) => {
    setDogData((prevDog) => {
      const currentValues = prevDog[name];
      if (currentValues.includes(value)) {
        // Remove value if it already exists
        return { ...prevDog, [name]: currentValues.filter(item => item !== value) };
      } else {
        // Add value if it doesn't exist
        return { ...prevDog, [name]: [...currentValues, value] };
      }
    });
  };

  useEffect(() => {

  }, []);



  return (
    <>
      <div className="dog-options">
        <h4 className="mt-0">Energy Levels</h4>
        <button className={dogData.energyLevels === 1 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 1)}>Very High Energy</button>
        <button className={dogData.energyLevels === 2 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 2)}>High Energy</button>
        <button className={dogData.energyLevels === 3 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 3)}>Moderate Energy</button>
        <button className={dogData.energyLevels === 4 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 4)}>Low Energy</button>
        <button className={dogData.energyLevels === 5 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 5)}>Very Low Energy</button>
        <button className={dogData.energyLevels === 6 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 6)}>Active</button>
        <button className={dogData.energyLevels === 7 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 7)}>Playful</button>
        <button className={dogData.energyLevels === 8 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 7)}>Calm</button>
        <button className={dogData.energyLevels === 9 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 9)}>Laid-Back</button>
        <button className={dogData.energyLevels === 10 ? btnActive : btnInactive} onClick={() => handleChange("energyLevels", 10)}>Sedentary</button>

        <h4>Dog Size</h4>
        <button className={dogData.dogSize === 1 ? btnActive : btnInactive} onClick={() => handleChange("dogSize", 1)}>Toy: Up to 6 kg</button>
        <button className={dogData.dogSize === 2 ? btnActive : btnInactive} onClick={() => handleChange("dogSize", 2)}>Small: 6 - 12 kg</button>
        <button className={dogData.dogSize === 3 ? btnActive : btnInactive} onClick={() => handleChange("dogSize", 3)}>Medium: 12 - 20 kg</button>
        <button className={dogData.dogSize === 4 ? btnActive : btnInactive} onClick={() => handleChange("dogSize", 4)}>Large: 20 - 30 kg</button>
        <button className={dogData.dogSize === 5 ? btnActive : btnInactive} onClick={() => handleChange("dogSize", 5)}>Extra Large: 30 - 45 kg</button>
        <button className={dogData.dogSize === 6 ? btnActive : btnInactive} onClick={() => handleChange("dogSize", 6)}>Giant: Over 45 kg</button>

        <h4>Dog Build</h4>
        <button className={dogData.dogBuild === 1 ? btnActive : btnInactive} onClick={() => handleChange("dogBuild", 1)}>Petite</button>
        <button className={dogData.dogBuild === 2 ? btnActive : btnInactive} onClick={() => handleChange("dogBuild", 2)}>Compact</button>
        <button className={dogData.dogBuild === 3 ? btnActive : btnInactive} onClick={() => handleChange("dogBuild", 3)}>Sturdy</button>
        <button className={dogData.dogBuild === 4 ? btnActive : btnInactive} onClick={() => handleChange("dogBuild", 4)}>Robust</button>

        <h4>Dog Type/Breed</h4>
        <button className={dogData.dogTypeBreed === 1 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 1)}>Sporting (e.g., Retrievers, Spaniels)</button>
        <button className={dogData.dogTypeBreed === 2 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 2)}>Hound (e.g., Beagles, Dachshunds)</button>
        <button className={dogData.dogTypeBreed === 3 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 3)}>Working (e.g., Boxers, Great Danes)</button>
        <button className={dogData.dogTypeBreed === 4 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 4)}>Terrier (e.g., Bull Terriers, Border Terriers)</button>
        <button className={dogData.dogTypeBreed === 5 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 5)}>Toy (e.g., Chihuahuas, Pomeranians)</button>
        <button className={dogData.dogTypeBreed === 6 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 6)}>Non-Sporting (e.g., Bulldogs, Dalmatians)</button>
        <button className={dogData.dogTypeBreed === 7 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 7)}>Herding (e.g., Shepherds, Collies)</button>
        <button className={dogData.dogTypeBreed === 8 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 8)}>Mixed Breed</button>
        <button className={dogData.dogTypeBreed === 9 ? btnActive : btnInactive} onClick={() => handleChange("dogTypeBreed", 9)}>Designer Breed (e.g., Labradoodles, Puggles)</button>

        <h4>Temperament</h4>
        <button className={dogData.temperament === 1 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 1)}>Friendly</button>
        <button className={dogData.temperament === 2 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 2)}>Protective</button>
        <button className={dogData.temperament === 3 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 3)}>Aggressive</button>
        <button className={dogData.temperament === 4 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 4)}>Shy</button>
        <button className={dogData.temperament === 5 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 5)}>Independent</button>
        <button className={dogData.temperament === 6 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 6)}>Sociable</button>
        <button className={dogData.temperament === 7 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 7)}>Playful</button>
        <button className={dogData.temperament === 8 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 8)}>Calm</button>
        <button className={dogData.temperament === 9 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 9)}>Nervous</button>
        <button className={dogData.temperament === 10 ? btnActive : btnInactive} onClick={() => handleChange("temperament", 10)}>Excitable</button>

        <h4>Walk Time</h4>
        <button className={dogData.walkTime.includes(1) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("walkTime", 1)}>Morning Walks</button>
        <button className={dogData.walkTime.includes(2) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("walkTime", 2)}>Afternoon Walks</button>
        <button className={dogData.walkTime.includes(3) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("walkTime", 3)}>Evening Walks</button>


        <h4>Walk Duration</h4>
        <button className={dogData.walkDuration === 1 ? btnActive : btnInactive} onClick={() => handleChange("walkDuration", 1)}>Short Walks (up to 15 minutes)</button>
        <button className={dogData.walkDuration === 2 ? btnActive : btnInactive} onClick={() => handleChange("walkDuration", 2)}>Medium Walks (15-30 minutes)</button>
        <button className={dogData.walkDuration === 3 ? btnActive : btnInactive} onClick={() => handleChange("walkDuration", 3)}>Long Walks (30+ minutes)</button>


        <h4>Walk Location</h4>
        <button className={dogData.walkLocation === 1 ? btnActive : btnInactive} onClick={() => handleChange("walkLocation", 1)}>Urban Walks</button>
        <button className={dogData.walkLocation === 1 ? btnActive : btnInactive} onClick={() => handleChange("walkLocation", 1)}>Suburban Walks</button>
        <button className={dogData.walkLocation === 1 ? btnActive : btnInactive} onClick={() => handleChange("walkLocation", 1)}>Park Walks</button>
        <button className={dogData.walkLocation === 1 ? btnActive : btnInactive} onClick={() => handleChange("walkLocation", 1)}>Trail Walks</button>

        <h4>Walking Pace</h4>
        <button className={dogData.walkingPace === 1 ? btnActive : btnInactive} onClick={() => handleChange("walkingPace", 1)}>Leisurely</button>
        <button className={dogData.walkingPace === 2 ? btnActive : btnInactive} onClick={() => handleChange("walkingPace", 2)}>Brisk</button>
        <button className={dogData.walkingPace === 3 ? btnActive : btnInactive} onClick={() => handleChange("walkingPace", 3)}>Steady</button>
        <button className={dogData.walkingPace === 4 ? btnActive : btnInactive} onClick={() => handleChange("walkingPace", 4)}>Casual</button>
        <button className={dogData.walkingPace === 5 ? btnActive : btnInactive} onClick={() => handleChange("walkingPace", 5)}>Quick</button>

        <h4>Health Considerations</h4>
        <button className={dogData.healthConsiderations === 1 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 1)}>No Special Considerations</button>
        <button className={dogData.healthConsiderations === 2 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 2)}>Joint Issues</button>
        <button className={dogData.healthConsiderations === 3 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 3)}>Respiratory Issues</button>
        <button className={dogData.healthConsiderations === 4 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 4)}>Allergies</button>
        <button className={dogData.healthConsiderations === 5 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 5)}>Mobility Issues</button>
        <button className={dogData.healthConsiderations === 6 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 6)}>Hearing Impairment</button>
        <button className={dogData.healthConsiderations === 7 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 7)}>Vision Impairment</button>
        <button className={dogData.healthConsiderations === 8 ? btnActive : btnInactive} onClick={() => handleChange("healthConsiderations", 8)}>Senior Dog Needs</button>

        <h4>Age</h4>
        <button className={dogData.age === 1 ? btnActive : btnInactive} onClick={() => handleChange("age", 1)}>Puppy (up to 6 months)</button>
        <button className={dogData.age === 2 ? btnActive : btnInactive} onClick={() => handleChange("age", 2)}>Adolescent (6 months - 1 year)</button>
        <button className={dogData.age === 3 ? btnActive : btnInactive} onClick={() => handleChange("age", 3)}>Young Adult (1-3 years)</button>
        <button className={dogData.age === 4 ? btnActive : btnInactive} onClick={() => handleChange("age", 4)}>Adult (4-7 years)</button>
        <button className={dogData.age === 5 ? btnActive : btnInactive} onClick={() => handleChange("age", 5)}>Mature Adult (7-10 years)</button>
        <button className={dogData.age === 6 ? btnActive : btnInactive} onClick={() => handleChange("age", 6)}>Elderly (10+ years)</button>


        <h4>Training Level</h4>
        <button className={dogData.trainingLevel === 1 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 1)}>Not Trained</button>
        <button className={dogData.trainingLevel === 2 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 2)}>Basic Commands</button>
        <button className={dogData.trainingLevel === 3 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 3)}>Intermediate Commands</button>
        <button className={dogData.trainingLevel === 4 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 4)}>Advanced Commands</button>
        <button className={dogData.trainingLevel === 5 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 5)}>Fully Trained</button>
        <button className={dogData.trainingLevel === 6 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 6)}>Obedience Training</button>
        <button className={dogData.trainingLevel === 7 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 7)}>Leash Training</button>
        <button className={dogData.trainingLevel === 8 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 8)}>Socialisation Training</button>
        <button className={dogData.trainingLevel === 9 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 9)}>House Trained</button>
        <button className={dogData.trainingLevel === 10 ? btnActive : btnInactive} onClick={() => handleChange("trainingLevel", 10)}>Trick Training</button>

        <h4>Owner&apos;s Availability</h4>
        <button className={dogData.ownersAvailability === 1 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 1)}>Weekdays Only</button>
        <button className={dogData.ownersAvailability === 2 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 2)}>Weekends Only</button>
        <button className={dogData.ownersAvailability === 3 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 3)}>Mornings Only</button>
        <button className={dogData.ownersAvailability === 4 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 4)}>Afternoons Only</button>
        <button className={dogData.ownersAvailability === 5 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 5)}>Evenings Only</button>
        <button className={dogData.ownersAvailability === 6 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 6)}>Flexible Schedule</button>
        <button className={dogData.ownersAvailability === 7 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 7)}>Specific Days (e.g., Monday, Wednesday, Friday)</button>
        <button className={dogData.ownersAvailability === 8 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 8)}>Any Time</button>
        <button className={dogData.ownersAvailability === 9 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 9)}>After Work Hours</button>
        <button className={dogData.ownersAvailability === 10 ? btnActive : btnInactive} onClick={() => handleChange("ownersAvailability", 10)}>Early Mornings</button>

        <h4>Play Style</h4>
        <button className={dogData.playStyle.includes(1) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 1)}>Fetch</button>
        <button className={dogData.playStyle.includes(2) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 2)}>Tug-of-War</button>
        <button className={dogData.playStyle.includes(3) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 3)}>Chase</button>
        <button className={dogData.playStyle.includes(4) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 4)}>Wrestling</button>
        <button className={dogData.playStyle.includes(5) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 5)}>Gentle Play</button>
        <button className={dogData.playStyle.includes(6) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 6)}>Rough Play</button>
        <button className={dogData.playStyle.includes(7) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 7)}>Solo Play</button>
        <button className={dogData.playStyle.includes(8) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 8)}>Interactive Play</button>
        <button className={dogData.playStyle.includes(9) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 9)}>Quiet Play</button>
        <button className={dogData.playStyle.includes(10) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("playStyle", 10)}>Exploratory Play</button>

        <h4>Noise Sensitivity</h4>
        <button className={dogData.noiseSensitivity === 1 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 1)}>Not Sensitive</button>
        <button className={dogData.noiseSensitivity === 2 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 2)}>Mildly Sensitive</button>
        <button className={dogData.noiseSensitivity === 3 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 3)}>Moderately Sensitive</button>
        <button className={dogData.noiseSensitivity === 4 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 4)}>Very Sensitive</button>
        <button className={dogData.noiseSensitivity === 5 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 5)}>Extremely Sensitive</button>
        <button className={dogData.noiseSensitivity === 6 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 6)}>Tolerates Loud Noises</button>
        <button className={dogData.noiseSensitivity === 7 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 7)}>Prefers Quiet</button>
        <button className={dogData.noiseSensitivity === 8 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 8)}>Reactive to Sudden Noises</button>
        <button className={dogData.noiseSensitivity === 9 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 9)}>Easily Startled</button>
        <button className={dogData.noiseSensitivity === 10 ? btnActive : btnInactive} onClick={() => handleChange("noiseSensitivity", 10)}>Calm in Noise</button>


        <h4>Location Preferences</h4>
        <button className={dogData.locationPreferences.includes(1) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 1)}>Nearby Park</button>
        <button className={dogData.locationPreferences.includes(2) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 2)}>Beach</button>
        <button className={dogData.locationPreferences.includes(3) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 3)}>Woods</button>
        <button className={dogData.locationPreferences.includes(4) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 4)}>Neighborhood</button>
        <button className={dogData.locationPreferences.includes(5) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 5)}>Dog Park</button>
        <button className={dogData.locationPreferences.includes(6) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 6)}>Hiking Trail</button>
        <button className={dogData.locationPreferences.includes(7) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 7)}>Urban Area</button>
        <button className={dogData.locationPreferences.includes(8) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 8)}>Suburban Area</button>
        <button className={dogData.locationPreferences.includes(9) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 9)}>Open Field</button>
        <button className={dogData.locationPreferences.includes(10) ? btnActive : btnInactive} onClick={() => handleMultiSelectChange("locationPreferences", 10)}>Riverside</button>


        <h4>Weather Preferences</h4>
        <button className={dogData.weatherPreferences === 1 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 1)}>Any Weather</button>
        <button className={dogData.weatherPreferences === 2 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 2)}>Sunny</button>
        <button className={dogData.weatherPreferences === 3 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 3)}>Cloudy</button>
        <button className={dogData.weatherPreferences === 4 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 4)}>Light Rain</button>
        <button className={dogData.weatherPreferences === 5 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 5)}>No Rain</button>
        <button className={dogData.weatherPreferences === 6 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 6)}>Snowy</button>
        <button className={dogData.weatherPreferences === 7 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 7)}>Cool Weather</button>
        <button className={dogData.weatherPreferences === 8 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 8)}>Warm Weather</button>
        <button className={dogData.weatherPreferences === 9 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 9)}>Mild Weather</button>
        <button className={dogData.weatherPreferences === 10 ? btnActive : btnInactive} onClick={() => handleChange("weatherPreferences", 10)}>No Extreme Temperatures</button>


        <h4>Socialisation Needs</h4>
        <button className={dogData.socialisationNeeds === 1 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 1)}>High Socialization</button>
        <button className={dogData.socialisationNeeds === 2 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 2)}>Moderate Socialization</button>
        <button className={dogData.socialisationNeeds === 3 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 3)}>Low Socialization</button>
        <button className={dogData.socialisationNeeds === 4 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 4)}>Needs Socialization Training</button>
        <button className={dogData.socialisationNeeds === 5 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 5)}>Good with Other Dogs</button>
        <button className={dogData.socialisationNeeds === 6 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 6)}>Good with People</button>
        <button className={dogData.socialisationNeeds === 7 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 7)}>Prefers Solo Walks</button>
        <button className={dogData.socialisationNeeds === 8 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 8)}>Plays Well with Others</button>
        <button className={dogData.socialisationNeeds === 9 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 9)}>Shy but Sociable</button>
        <button className={dogData.socialisationNeeds === 10 ? btnActive : btnInactive} onClick={() => handleChange("socialisationNeeds", 10)}>Prefers Familiar Dogs</button>

      </div>









    </>
  );
};