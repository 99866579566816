/* eslint-disable react/prop-types */
import { Component } from 'react';
import { Link } from 'react-router-dom';

export class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hideBack: false,
    };
  }

  componentDidMount() {
    let currentPath = window.location.pathname;
    let hideBack = currentPath === "/";
    this.setState({ hideBack });

  }

  render() {
    return (
      <>
        <header >
          <table className='w-100'>
            <tbody>
              <tr>
                <td style={{width: "80px"}}><Link to={-1}><i className='fa fa-chevron-left' hidden={this.state.hideBack || this.props.hideBack}></i></Link></td>
                <td className='text-center'>
                  {this.props.Heading}
                  
                </td>
                <td className='text-end' style={{width: "80px"}}>
                  <i className='fas fa-magnifying-glass fw-bold pe-3' ></i>
                  {/* <NavbarToggler onClick={this.toggleSearch} hidden={!this.state.isSearchable}><i className='fas fa-magnifying-glass fw-bold'></i></NavbarToggler> */}
                </td>
              </tr>
            </tbody>
          </table>

        </header>

      </>
    );
  }


}
