import { useEffect, useState } from 'react';
import { Icon } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import { getAuthToken } from '../helpers/authentication';
import Loader from '../components/Common/Loader';
import { PlaceTypes } from '../helpers/global';
import { APIPath } from "../helpers/APIHelper";
import { Inject, CheckBoxSelection, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';

//const datasource = data;


const FindNearby = () => {
  const [mapData, setMapData] = useState();
  const [loading, setLoading] = useState(true);
  const [mapFilter, setMapFilter] = useState([]);

  const streetMap = {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
    maxZoom: 19
  };

  const myIcon = new Icon({
    iconUrl: '/assets/maps/location-pin-dog-friendly.svg',
    iconSize: [21, 27],
  });


  const loadData = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + '/api/places', {
        method: 'GET',
        withCredentials: true,
        //credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setMapData(data);
        setLoading(false);
        console.log(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }


  useEffect(() => {
    loadData();
  }, []);


  const isMarkerVisible = (marker) => {
    const placeTypes = JSON.parse(marker.placeType);
    return mapFilter.length === 0 || placeTypes.some(type => mapFilter.includes(type));
  };

  if (loading)
    return <Loader />;

  //https://react-leaflet.js.org/docs/example-layers-control/
  return (
    <main>
      <div className='text-center pt-2 px-3'>
        <MultiSelectComponent placeholder="Filter Categories" value={mapFilter} fields={{ text: 'title', value: 'id' }} change={e => setMapFilter(e.value)} dataSource={PlaceTypes} mode="CheckBox" >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
      <div className='locationmap-container'>

        <MapContainer center={[-26.0172312, 28.0303184]} zoom={13} scrollWheelZoom={true} className='map' preferCanvas={true} touchZoom={true} zoomAnimation={true}  >
          <TileLayer attribution={streetMap.attribution} url={streetMap.url} maxZoom={streetMap.maxZoom} />
          <MarkerClusterGroup chunkedLoading>
            {mapData.map((item, index) => (
              <Marker key={index} position={[item.latitude, item.longitude]} icon={myIcon}>
                <Popup>
                  <b>{item.title}</b><br />
                  { item.shortAddress}
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>


      </div>
    </main>
  );
};
export default FindNearby;
