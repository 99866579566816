import { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '../Nav/footer';
import { Header } from '../Nav/header';

export class DefaultLayout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showHeader: true,
      showFooter: true
    };
  }
  componentDidMount() { }

  onRefresh() {
    window.location.reload();
  }

  render() {
    return (
      <>
        {/* <PullToRefresh
          pullDownContent={<PullDownContent />}
          releaseContent={<ReleaseContent />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={100}
          onRefresh={this.onRefresh}
          triggerHeight={400}
          backgroundColor='white'
          startInvisible={true}
          className="content-container" > */}
          <Header Heading="Dog Friendly" />
          <div className='bottom-spacer'>
            <Outlet />
          </div>
          <Footer />

        {/* </PullToRefresh> */}

      </>
    );
  }
}
