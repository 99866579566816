//import { useEffect, useState } from 'react';

const Medical = () => {
  return (<>
    <h1>Medical</h1>
  </>);
};
export default Medical;


